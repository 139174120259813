@use "../variables";

.searches {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 85px;
  padding: 2.5rem 0;
}

.record-search {
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  .ant-input {
    border-radius: 4px;
    height: 38px;
    background: transparent;
    border-color: #38383d;
    color: variables.$baseTextColor;
    border: 1px solid variables.$borderColor;
    box-shadow: none !important;
    width: 100%;
    font-size: 12px;
    border-right: 0px;
    &::placeholder{
      font-size: 14px;
      font-family: Font-Medium;
      color: variables.$tabsActiveColor;
    }
  }
  .ant-input-search-button {
    height: 38px;
  }

  // .ant-input-wrapper {
  //   &:hover,
  //   &:focus {
  //     .ant-input {
  //       border-color: variables.$linkColor;
  //     }
  //     .ant-btn {
  //       border-color: variables.$linkColor;
  //     }
  //   }
  // }

  // .ant-btn-primary {
  //   border-color: variables.$primaryColor;
  //   background: variables.$primaryColor;
  // }

  // .ant-btn-primary:focus,
  // .ant-btn-primary:hover {
  //   border-color: variables.$baseTextColor;
  //   background: variables.$baseTextColor;
  // }

  .ant-input-group-addon {
    background: transparent;
  }
  &.ant-input-search {
    .ant-input {
      &:hover,
      &:focus {
        border-color: variables.$borderColor;
      }
    }
    & > .ant-input-group {
      & > .ant-input-group-addon:last-child {
        .ant-input-search-button {
          border-radius: 0 4px 4px 0;
          border-left: 0px;
          border-color: variables.$borderColor;

          svg {
            // opacity: 1;
            color: variables.$linkColor;
          }
        }
      }
    }
  }
}
