@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";
@import "../../../assets/sass/layout/tag.scss";
.project-specific-config {
  &-add {
    text-align: center;
    padding: 60px;
    min-height: 320px;
    h3 {
      @include font-size-color(16px, $baseTextColor, null, 18px);
      padding-bottom: 10px;
    }
    p {
      padding-bottom: 20px;
      @include font-size-color(14px, $baseTextColor);
    }
  }
  &-edit {
    &-container {
      margin-top: 30px;
      .container {
        &-title {
          padding: 10px 20px;
          border-bottom: 1px solid $CardBorderColor;
          background: #F8F8FF;
          border-radius: 3px 3px 0px 0px;
          .icon-btn {
            background: transparent;
            border: none;

            &.ant-btn {
              background: transparent;
              color: $linkColor;
            }
            &.ant-btn:focus,
            &.ant-btn:hover {
              box-shadow: none;
              opacity: 1;
              outline: none;
            }
            &.ant-btn-icon-only {
              width: initial;
              height: initial;
              padding: 0px 0px;
              border: none;
              margin-top: 5px;
            }
          }
        }
        &-content {
          border: 1px solid $CardBorderColor;
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-radius: 0px 0px 3px 3px;


          h3 {
            color: $baseTextColor;
            font-size: 12px;
            font-family: Font-Medium;
            padding-bottom: 5px;
          }
          .columns-group,
          .probability-group {
            border-left: 1px solid $CardBorderColor;
            padding: 20px;
          }
          .detail-group,
          .title-group {
            border-left: 1px solid $CardBorderColor;
            padding: 20px;
          }

          .columns-group,
          .detail-group {
            div {
                overflow-y: auto;
                max-height: 100px;
            }

            p {
              margin-top: 10px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .ant-progress-inner{
    border-radius: 4px !important;
    .ant-progress-bg{
      border-radius: 4px !important;

    }
  }
}
.ant-click-animating-node,
[ant-click-animating-without-extra-node="true"]:after {
  box-shadow: none;
}

.insigt-button-container{
  display: flex;
  gap: 10px;
  justify-content: center;
}