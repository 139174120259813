@import "../../assets/sass/variables";
// @import "../../assets/sass/mixins";

.user-page {
  height: 100vh;
  background: $bgGradient;

  a {
    color: rgba($color: $baseColor, $alpha: 0.8);
    font-family: Font-Medium;
    &:hover {
      color: rgba($color: $baseColor, $alpha: 1);
    }
  }

  &-container {
    width: 55%;
    gap: 90px;
    .microsoft-text {
      padding-left: 90px;
      border-left: 1px solid rgba($baseColor, 0.5);
      h5 {
        font-size: 14px;
        color: $baseColor;
        margin: 10px 0 25px 0;
      }
    }
  }
  .login {
    &-title {
      font-size: 30px;
      color: $tabdetail;
      font-family: Font-Semibold;
      margin: 20px 0 30px 0;
    }
  }
  .autonomizeModal {
    .ant-form-item {
      margin-bottom: 40px;
    }
  }
  .login-button {
    min-width: 93px;
    height: 36px;
    background: transparent;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-color: $baseColor;
    color: $baseColor;
    font-size: 12px;
    font-family: Font-medium;

    &:hover {
      background: $loginbtn;
      border-color: $loginbtn;
      color: $baseColor;
    }
  }
}
.login-content-modal {
  padding: 30px 90px;
}
.email-confirmation {
  svg {
    width: 53px;
    height: 45px;
  }
  h3 {
    margin-top: 20px;
    color: $tabdetail;
    font-family: Font-Semibold;
    color: $baseColor;
  }
}

.user-form {
  &.autonomizeModal {
    .ant-input,
    .ant-input-affix-wrapper {
      background: rgba($color: $baseColor, $alpha: 0.2);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      border: 0px;
      color: $baseColor;
      padding: 10px 14px;
    }

    .ant-input-affix-wrapper {
      .ant-input {
        padding: 0;
        background: rgba($color: $baseColor, $alpha: 0);
      }
    }

    .ant-input-password-icon.anticon {
      color: $baseColor;
    }

    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:hover {
      background: rgba($color: $baseColor, $alpha: 0.2);
      color: $baseColor;
    }
  }
  h2 {
    font-family: Font-Semibold;
    margin: 0px 0 20px;
    color: $baseColor;
  }
  h4,
  p {
    color: rgba($color: $baseColor, $alpha: 0.8);
  }
  &-message {
    padding-bottom: 30px;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      margin-top: 0px;
      box-shadow: 0px 0px 0px;
    }
    // &:hover {
    //   border-color: $linkColor;
    // }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $linkColor;
    box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
  }
}
.brand-logo {
  align-items: baseline;
  justify-content: flex-start;
  gap: 20px;
  &-img {
    height: 48px;
  }
}

.brand-title {
  color: #fff;
  font-size: 48px;
  font-family: "Damion", cursive;
  line-height: 1;
  &-highlight {
    color: #e8ae55;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
