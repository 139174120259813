@use "../../../assets/sass/variables";


 .ask-auto{
            background: linear-gradient(116.02deg, #530B7E 24.28%, #982068 110.99%);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            padding: 8px 10px; 
            color: white;  
 }


