* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.ant-layout {
  &.ant-layout-has-sider {
    height: 100vh;
    // background: $baseColor;
    & > .ant-layout {
      height: 100vh;
      background: $bgGradient;
    }
  }
}

li {
  list-style-type: none;
  // text-align: center;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0px;
}

.demo-text {
  color: rgb(209, 0, 0) !important;
  // font-size:16px;
  display: block !important;
}

.no-grid {
  display: block;
  overflow-y: auto;
  height: calc(100% - 140px);
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-top: 2px;
}

.mandatory-text {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mandatory {
  font-style: italic;
  font-size: 12px;
}
