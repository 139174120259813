@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";
.profile-info {

  &-name {
    @include font_size_color(16px, variables.$titleColor, null, null, Font-Semibold);
  }

  &-desc {
    @include font_size_color(12px, variables.$baseTextColor);
  }
}

.assigned-project-container{
  background: linear-gradient(116.02deg, #530B7E 24.28%, #982068 110.99%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding:10px;

  h3{
    color: variables.$baseColor;
  }
}

.data-sources-container{
  background: variables.$baseColor;
  border: 1px dashed variables.$borderColor;
  padding: 15px;
  border-radius: 4px;
}

.patient-aside-info-title{
  color: variables.$tabsActiveColor;
  + span{
    color: variables.$baseTextColor;
    font-family: Font-Medium;
  }
}