.annotation-table {
  .ant-table-container table {
    .ant-table-cell {
      &:nth-child(2),
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 15%;
        text-align: center;
      }
    }
  }
}
