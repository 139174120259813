@use '../../assets/sass/variables';

.d {
  &-sidebar {
    // min-width: 297px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    // background-color: #25262b;
    min-height: 100vh;

    &-title {
      margin-bottom: 30px;
      padding-bottom: 1rem;
      border-bottom: 1px solid variables.$borderColor;

      h4 {
        margin: 0;
      }

      // .ai-select-box {
      //   border-color: variables.$textSecondaryColor;
      // }
    }

    .ant-card {
      border-width: 1px;

      &-body {
        padding: 1rem;
      }
    }
  }
}

// .dashboard {
//   display: grid;
//   grid-template-areas: "result sidebar";
//   column-gap: 30px;
//   grid-template-columns: 1fr 370px;
// }
