@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.e {
  &-content {
    padding-left: 1.5rem;
    // background-color: ;
    // font-size: 0.825rem;
    line-height: 2;
    // font-weight: 500;
    color: variables.$baseTextColor;
    margin: 22px 0;

    .e-paragraph {
      // font-size: 14px;
      padding: 0 20px 0 0 !important;
      height: 100%;
      min-height: 600px;
      max-height: 600px;
      overflow-y: auto;
      word-break: break-word;
    }

    &-subtitle {
      font-family: Font-Semibold;
      @include font_size_color(null, variables.$titleColor, 500, null);
      display: block;
    }
    &-chips {
      gap: 10px;
      margin: 10px 0 20px 0;
    }
    &-chip {
      // border: 1px solid variables.$borderColor;
      padding: 6px 10px;
      border-radius: 4px;
      margin-bottom: 0px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      // font-weight: 600;
    }
  }

  &-feedback {
    .ant-row {
      padding-right: 1.5rem;
      margin-top: 30px;
      display: block;
    }

    .ant-form-item-label > label {
      color: variables.$titleColor;
      font-size: 14px;
      font-family: Font-Semibold;
    }

    .ant-input,
    .ant-input-affix-wrapper {
      margin-top: 6px;
      // background: #3b3b3b !important;
      color: variables.$baseTextColor !important;
      border-radius: 4px;
      border: 0px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    }

    .submitIcon {
      .flex {
        gap: 10px;
      }

      .aiButton {
        width: 110px;
        height: 30px;
        min-width: 110px;
      }
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
      display: none;
    }
  }
}

@media screen and (min-width: 1921px) {
  .e {
    &-content {
      height: auto; 
      .e-paragraph {
        min-height: unset; 
        max-height: unset; 
      }
    }
  }
}

@media screen and (max-width: 1920px) {
  .e {
    &-content {
      .e-paragraph {
        min-height: 996px;
        max-height: 996px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .e {
    &-content {
      .e-paragraph {
        min-height: 686px;
        max-height: 686px;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .e {
    &-content {
      .e-paragraph {
        min-height: 600px;
        max-height: 600px;
      }
    }
  }
}
