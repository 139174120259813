.ant-select-dropdown {
  color: $baseTextColor;
  font-size: 1rem;
  box-shadow: none;
  background: $baseColor;
  border: 1px solid $linkColor;
  border-radius: 4px;

  .ant-select-item {
    min-height: auto;
    font-size: 12px;
    color: $baseTextColor;
  }

  .ant-select-item-option {
    padding: 0.375rem;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $linkColor;
    font-weight: normal;
    background-color: transparent;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: $baseColor;
    background: $linkColor;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: $linkColor;
  }
}

.assigned-project-dropdown{
  .ai-select {
    font-size: 14px;
    font-family: Font-SemiBold;
  }
  .ai-select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: transparent;
    color:$baseColor;
    border: 0px;
    padding:0px;
    height:30px;
    outline: none;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 0 0 2px rgba(59, 112, 205, 0);
  }
  .ai-select .ant-select-arrow{
    color: $baseColor;
  }
}