@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";
@import "../../assets/sass/accordion";

.searches {
  ul {
    padding-right: 0;
  }
  &-li {
    line-height: 14px;
    padding: 8px 0;
    border-bottom: 1px solid variables.$borderColor;

    &-right {
      gap: 10px;
      opacity: 0.5;
      &.flex {
        flex-wrap: nowrap;
      }
    }
    &.flex {
      flex-wrap: nowrap;
    }
  }

  &-title {
    @include font_size_color(12px, variables.$tabdetail, normal, 14px);
    width: calc(100% - 45px);
  }
  &-date {
    @include font_size_color(12px, variables.$baseTextColor, normal, 14px);
    width: 135px;
    max-width: 135px;
    margin-top: 5px;
  }
}

.ant-collapse-content-active .scrollBar {
  max-height: calc(100vh - 370px);
  padding-right: 10px;
}
