@import "../../../assets/sass/variables";

.project-card {
  &:last-child {
    padding-top: 20px;
  }
  &-label {
    font-family: Font-Semibold;
    display: block;
  }
  &-value {
    display: block;
    font-size: 28px;
    // font-weight: 700;
    // font-family: DmSans-Medium;
    // color: $textPrimaryColor;

    &-number {
      font-size: 16px;
    }
  }
}
.projectsList {
  width: 100%;
  h3 {
    font-size: 18px;
    color: #1e293b;
  }
}
.view-control {
  opacity: 0.2;
  &.active {
    color: #2e5ebc;
    opacity: 1;
  }
}
.grid-View {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.project-card-full {
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.project-card-view {
  &-dis {
    max-height: 100px;
    overflow: auto;
  }
  .project-name {
    font-size: 14px;
    font-family: Font-Semibold;
    color: #475569;
  }
  p {
    font-size: 12px;
  }
  .ql-editor {
    padding: 12px 0px;
  }
  .highlight {
    font-family: Font-Semibold;
  }
}
