@use "../../../assets/sass/variables";
.project-specific {
  &-title {
    font-size: 22px;
    padding-bottom: 20px;

    &.tp-border {
      padding-top: 30px;
      border-top: 1px solid variables.$borderColor;
    }
  }

  .ant-card {
    padding: 0 auto;
  }

  .ant-table {
    padding: 0px;
  }

  .nsclc-table {
    .ant-table-cell:nth-child(3) {
      text-align: right;
    }
  }

  .specific-insight {
    text-decoration: none;
    font-size: 14px;
    color: variables.$baseTextColor;
    opacity: 0.4;
  }
  .ant-table .ant-table-tbody > tr > td,
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table tfoot > tr > td,
  .ant-table .ant-table tfoot > tr > th {
    padding: 10px 0;
  }
}

.clinical-insight {
  overflow-y: auto;
  max-height: calc(100vh - 364px);
  padding-right: 20px;
  .anticon svg {
    width: 14px;
    height: 14px;
    color: #767279;
    &:hover {
      background: variables.$linkColor;
      color: variables.$baseColor;
      border-radius: 50%;
    }
  }
}

.data-two-column {
  &-score {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    overflow-y: auto;
    max-height: 180px;
  }
  &-row {
    border-bottom: 1px solid rgba(variables.$baseTextColor, 0.2);
  }
}

.data-title {
  color: variables.$tabsActiveColor;
  &-text {
    font-family: Font-Medium;
    padding: 8px 0;
    max-width: 130px;
    &-tag {
      background: variables.$baseColor;
      padding: 6px 15px;
    }
  }
  &-score {
    color: variables.$tabsActiveColor;
    font-family: Font-Bold;
    padding: 8px;
    &-tag {
      padding: 6px 10px;
      color: variables.$tabsActiveColor;
    }
  }
}
.data-list {
  margin-left: 15px;
  li {
    list-style-type: disc;
    line-height: 2;
  }
}
.tiles-max-height {
  overflow-y: auto;
  max-height: 180px;
  &-table {
    .ant-table {
      .ant-table-thead {
        > tr > th {
          padding-top: 0px;
          border-bottom: 0px;
        }
      }
      .ant-table-tbody {
        max-height: 160px;
        padding-right: 10px;

        > tr > td,
        > tr > th {
          border-color: rgba(variables.$baseTextColor, 0.2);
        }
      }
    }
  }
}

.patient-insight-tag {
  border: 2px solid variables.$tagButtonBorderColor;
  background: #efecf6;
  border-radius: 4px;
}
.patient-insight-element {
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px dashed variables.$borderColor;
  color: variables.$baseTextColor;
  font-family: Font-Medium;
  &:hover {
    background: #f5f5f6;
    color: variables.$linkColor;
  }
}

// .clinical-insight {
//   overflow-y: auto;
//   max-height: calc(100vh - 364px);
//   padding-right: 8px;
// }
