@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.ant-layout-sider-trigger {
  // margin-bottom: 10px !important;
  width: auto !important;
  background: transparent !important;
  svg {
    width: 14px;
    height: 14px;
  }
}

.sidebar {
  .logo {
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    padding: 25px;
    background: variables.$bgGradient;
    height: 100%;
    .ant-layout-sider-children {
      height: 62%;
      display: flex;
      flex-direction: column;
    }
  }

  &-nav {
    &-link {
      margin: 30px 0;
      position: relative;
      color: rgba($color: variables.$baseColor, $alpha: 0.6);

      &.active,
      &:hover {
        // background: variables.$lightPurpleColor;
        color: variables.$baseColor;
        // padding:8px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.rotate-icon {
  transform: rotate(180deg);
}
