// $bgColor: #1c1d22;
$primaryColor: #e8ae55;
// $buttonHoverColor: #da8e19;
// $secondaryColor: #314e98;
// $textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
// $text-color1: #a4a5a7;
// $placeTextColor: #505053;
// $cardBgColor: #303136;
// $orange: #d87e14;
// $red: #c2334d;
// $blue: #4743db;

// $lightgray: #ededed;

$tabdetail: #1e293b;
$green: #44c658;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #635f6a;
$titleColor: #301934;
$smTitleColor: #02462d;
$linkColor: #2e5ebc;
$borderColor: #e7ecf4;
$CardBorderColor: #f5f9ff;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
$lightgrayColor: #f7f7f8;
$darkLilacColor: #9e679c;
$lightLilacColor: #c288cd;
$royalOrangeColor: #f49a47;
$purpleColor: #410a63;
$lightPurpleColor: #7962ba;
$electricPurpleColor: #bd00ff;
$tabsActiveColor: #383048;
$tagButtonBorderColor: #efecf6;
$bgGradient: linear-gradient(180deg, #270067 48.96%, #8626c0 100%);
$bgBtn: linear-gradient(90deg, #3232a6 0%, #8626c0 100%);
$loginbtn:#8626c0;
$blueTheme: #3232a6;