@use "../../../assets/sass/variables";

.measureTable {
  border: 1px solid variables.$borderColor;
  border-radius: 6px;

  .ant-table-cell {
    color: #475569;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-table .ant-table-thead>tr>th {
    background: #FBFBFB;
    border: 0px;
  }

  .ant-table-tbody {
    background: #FBFBFB;
  }

  .ant-table-expanded-row {
    border: 0px !important;
    border-radius: 0px !important;
    margin: 0px !important;
    background: white !important;
    width: 100% !important;
  }

  .ant-table .ant-table-tbody tr {
    border: 1px solid variables.$borderColor;
    border-radius: 6px;
    margin: 10px;
    background: white;
    width: 98%;
  }

  &-collapseIcon {
    border-radius: 50% !important;
    border: 1px solid variables.$blueTheme !important;
    height: 16px !important;
    width: 16px !important;
    padding: 1px !important;
    font-size: 12px;
  }

  &-expandIcon {
    border-radius: 50% !important;
    border: 1px solid variables.$blueTheme !important;
    color: variables.$baseColor !important;
    background-color: variables.$blueTheme !important;
    height: 16px !important;
    width: 16px !important;
    padding: 1px !important;
    font-size: 12px;
  }

  &-measurename {
    width: 45%;
  }

  .ant-table-row-expand-icon-cell {
    width: 30px;
  }

  .ant-table .ant-table-tbody {
    max-height: 400px;
  }
}

.expandedTable {
  .ant-table-cell {
    padding: 5px 10px !important;
    max-height: 50px;
    overflow: auto;
    color: #1E293B;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ant-table .ant-table-tbody tr {
    border: 0px;
    border-radius: 0px;
    margin: 0px;
    background: white;
    width: 100%;
  }

  .ant-table .ant-table-thead tr {
    border-top: 1px solid variables.$borderColor;
    border-bottom: 1px solid variables.$borderColor;
    border-left: 0px;
    border-right: 0px;
    margin: 0px;
    background: white;
    width: 100%;
  }

  &-question {
    width: 48%;
  }

  &-evidence {
    width: 100px;
    padding: 10px;
  }
}

.rationaleBox {
  max-height: 90px;
  padding: 10px 0px;
  overflow: auto;
}

.row-expanded {
  .ant-table-cell {
    color: #5F3CAB !important;
  }
}