@use "../../../assets/sass/variables";
@import "../../../assets/sass/layout/search.scss";

.patients-tab {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 10px;
  row-gap: 40px;
  align-items: center;
  .ant-table-wrapper {
    grid-column: span 2;
    background: #fff;
    border-radius: 3px;
  }

  .tb-scroll {
    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 312px);
      }
    }
    &.four-column {
      .ant-table-container table {
        .ant-table-cell {
        text-align: center;
        }
        .ant-table-cell:first-child {
          text-align: left;
          }
      }
    }
  }

  .ant-table .ant-table-container {
    text-align: left;
  }
}

