@use "../../assets/sass/variables";
.patient-recommender {
  .cmn-table {
    .ant-table .ant-table-tbody {
      max-height: calc(100vh - 270px);
    }

    .ant-table-container table .ant-table-cell {
      text-align: left;

      &:nth-child(3) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
  .ant-tag {
    border: 1px solid variables.$CardBorderColor;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 5%);
    border-radius: 3px;
    display: inline-block;
    padding: 6px 10px;
    font-size: 12px;
    font-family: Font-Medium;
    background-color: transparent;

    &.green {
      color: variables.$green;
    }

    &.orange {
      color: #e8ae55;
    }

    &.red {
      color: #cb003f;
    }
  }
}
