@import "../../assets/sass/variables";

.addClient {
  width: 100%;
  min-width: 947px;
  max-width: 947px;

  &-title {
    padding: 25px 30px;
    border-bottom: 1px solid $borderColor;

    p {
      margin: 10px 0 0;
    }
  }

  &-content {
    padding: 30px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
}

.edit-profile {
  .ant-modal-body {
    .addClient-title {
      padding: 15px;
      border-bottom: none;
    }
  }

  .ant-radio-group {
    margin-top: 10px;
  }

  .ant-radio-wrapper {
    color: #fff;
    margin: 0 20px 0 0;

    .ant-radio-checked .ant-radio-inner {
      border-color: $linkColor;
    }

    .ant-radio-inner:after {
      background: $linkColor;
    }
  }
}
// *--------select dropdown----------*
.new-role {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background: transparent;
    border: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 10px 6px;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    margin-top: 5px;
    box-shadow: 0px 0px 26px rgb(0 0 0 / 5%);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: $baseColor;
  }

  // .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  //   border-color: $linkColor;
  // }

  // .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  //   .ant-select-selector {
  //   border-color: $linkColor;
  //   box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
  // }

  .ant-select-arrow .anticon > svg {
    color: $tabdetail;
  }

  .ant-select-arrow .anticon {
    margin-top: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.5715;
  }
}

.deleteConfirmation {
  padding: 80px 70px;
  border: 0px;

  h1 {
    font-family: Font-Semibold;
    // padding-bottom: 30px;
  }

  p {
    // font-size: 21px;
    padding-bottom: 30px;
    // color: $textPrimaryColor;
    margin-bottom: 0;
  }
}

.patient-project {
  text-align: center;

  .info {
    color: $baseColor;
    font-size: 16px;
  }
  ul {
    margin-bottom: 0px;
    max-height: 250px;
    overflow-y: auto;

    .ant-checkbox-wrapper {
      width: 100%;
      color: $baseColor;
      padding: 10px 0;
      border-bottom: 1px solid $borderColor;
    }
  }
  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: $linkColor;
  //   border-color: $linkColor;
  // }
  // .ant-checkbox-checked:after {
  //   border-color: $linkColor;
  // }
  // .ant-checkbox-input:focus + .ant-checkbox-inner,
  // .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  // .ant-checkbox:hover .ant-checkbox-inner {
  //   border-color: $linkColor;
  // }
  // .aiButton {
  //   margin: 40px 0 30px 0;
  // }
}

.modalButton {
  display: flex;
  align-items: center;
  justify-content: center;
}